@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DeliciousHandrawn';
  src: URL('./fonts/Delicious_Handrawn/DeliciousHandrawn-Regular.ttf') format('truetype');
}

.DeliciousHandrawn{
  font-family: DeliciousHandrawn;
}


.less_cool_button {
  border: 2px solid black;
  transition: box-shadow 0.3s ease;
}

.less_cool_button:hover {
  border: 2px solid black;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.3em 0px 0px #1a081c;
  transition: box-shadow 0.3s ease;
}

.less_cool_button_with_shadow_no_hover {
  border: 2px solid black;
  transition: box-shadow 0.3s ease;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.2em 0px 0px #1a081c;
}

.less_cool_button_with_shadow {
  border: 2px solid black;
  transition: box-shadow 0.3s ease;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.3em 0px 0px #1a081c;
}

.less_cool_button_with_shadow:hover {
  border: 2px solid black;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.5em 0px 0px #1a081c;
  transition: box-shadow 0.3s ease;
}

.cool_button {
	background: #aa8fff;
  border: 2px solid black;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.3em 0px 0px #1a081c;
  transition: box-shadow 0.3s ease;
}
.cool_button:hover {
        box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.4em 0px 0px #1a081c;
}

.half_cool_border {
	box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.4em 0px 0px #1a081c;
}

.cool_border {
	box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.8em 0px 0px #1a081c;
}